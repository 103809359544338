import React, { useContext } from 'react';
import { ThemeContext } from 'providers/ThemeProvider';
// import {Button, Container} from 'components/common';
import { Container } from 'components/common';
import { Details, IntroWrapper, Thumbnail, Wrapper } from './styles';
import { StaticImage } from 'gatsby-plugin-image';
import Link from 'gatsby-link';

export const About = ({ lang }) => {
  const { theme } = useContext(ThemeContext);
  const isEn = lang === 'en';
  return (
    <Wrapper id={'about'}>
      <IntroWrapper as={Container}>
        <Details theme={theme} isEn={isEn}>
          <h1>{isEn ? 'About Me' : '关于'}</h1>
          {isEn ? (
            <p>
              I am a professor in the{' '}
              <a href="https://religion.rutgers.edu/tao-jiang" target="_blank" rel="noreferrer">
                Department of Religion
              </a>{' '}
              and{' '}
              <a href="https://philosophy.rutgers.edu" target="_blank" rel="noreferrer">
                Department of Philosophy
              </a>{' '}
              at Rutgers University, New Brunswick, New Jersey, US.
            </p>
          ) : (
            <p>
              蒋韬，罗格斯大学（新布朗维斯克校区，新泽西州，美国）
              <a href="http://religion.rutgers.edu/tao-jiang" target="_blank" rel="noreferrer">
                宗教系
              </a>
              及
              <a href="https://philosophy.rutgers.edu" target="_blank" rel="noreferrer">
                哲学系
              </a>
              教授。
            </p>
          )}
          {
            <p>
              <a href={'/CV_Tao_Jiang.pdf'} target="_blank" rel="noreferrer">
                {isEn ? 'Curriculum Vitae' : '履历'}
              </a>
            </p>
          }
          {/*<Button as={Link} to={"/" + (isEn ? "" : "cn/") + "about/"}>*/}
          <Link to={'/' + (isEn ? '' : 'cn/') + 'about/'} style={{ fontWeight: 700 }}>
            {isEn ? 'Read more' : '更多信息'}
          </Link>
          {/*</Button>*/}
        </Details>
        <Thumbnail>
          <StaticImage
            src={'../../../assets/images/about.jpg'}
            alt={'Tao Jiang, Professor of Religion and Philosophy at Rutgers University, New Brunswick.'}
            placeholder="blurred"
            style={{ height: '100%', width: '100%', maxWidth: '300px' }}
            imgStyle={{ objectFit: 'contain' }}
          />
        </Thumbnail>
      </IntroWrapper>
    </Wrapper>
  );
};
