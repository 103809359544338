import styled from 'styled-components';
import overlayIllustration from 'assets/illustrations/overlay.svg';

export const Wrapper = styled.div`
  // padding-bottom: 4rem;
  // background-image: url(${overlayIllustration});
  // background-size: contain;
  // background-position: right top;
  // background-repeat: no-repeat;
  //
  // @media (max-width: 960px) {
  //   background-image: none;
  // }
`;

export const IntroWrapper = styled.div`
  padding: 4rem 0;
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 960px) {
    flex-direction: column;
  }

  @media (max-width: 960px) {
    padding: 0;
  }
`;

export const Details = styled.div`
  flex: 3;

  @media (max-width: 960px) {
    width: 100%;
    margin-bottom: 2rem;
  }

  h1 {
    margin-bottom: 2rem;
    font-size: 26pt;
    color: ${({ theme }) => (theme === 'dark' ? '#fff' : '#212121')};

    @media (max-width: 960px) {
      mix-blend-mode: ${({ theme }) => (theme === 'light' ? 'unset' : 'difference')};
    }
  }

  h4 {
    margin-bottom: 2.5rem;
    font-size: 25pt;
    font-weight: normal;
    // color: ${({ theme }) => (theme === 'light' ? '#707070' : '#e6e6e6')};
    color: ${({ theme }) => (theme === 'light' ? '#000' : '#e6e6e6')};

    @media (max-width: 960px) {
      mix-blend-mode: ${({ theme }) => (theme === 'light' ? 'unset' : 'difference')};
    }

    @media (max-width: 680px) {
      font-size: 20pt;
    }
  }

  p {
    margin-bottom: 2.5rem;
    font-size: 18px;
    font-weight: normal;
    line-height: 1.3;
    color: ${({ theme }) => (theme === 'dark' ? '#fff' : '#000')};
    //font-size: ${({ isEn }) => (isEn ? 'inherit' : '20px')};

    @media (max-width: 960px) {
      mix-blend-mode: ${({ theme }) => (theme === 'light' ? 'unset' : 'difference')};
    }
  }
`;

export const Thumbnail = styled.div`
  flex: 1;
  margin: 0 1em;

  @media (max-width: 960px) {
    width: 100%;
  }

  & img {
    width: 100%;

    @media (max-width: 680px) {
      max-height: 0;
      visibility: hidden;
    }
  }
`;
