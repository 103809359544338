import React, {useContext} from 'react';
import {StaticImage} from "gatsby-plugin-image";
import {ThemeContext} from 'providers/ThemeProvider';
import {Card, Container} from 'components/common';
import data from "../../../data/Data";
import {BookCover, Grid, Item, Wrapper} from './styles';
import Link from "gatsby-link";

export const Books = ({lang}) => {
  const {theme} = useContext(ThemeContext);
  const isEn = lang === "en"
  const books = [data.chinese_phil, data.buddhism, data.freud]
  return (
    <Wrapper as={Container} id="books">
      <h1>{isEn ? "Books" : "专著"}</h1>
      <Grid>
        <Item as={Link} to={"/" + (isEn ? "" : (lang + "/")) + "origins_of_chinese_phil/"} target="_blank"
              rel="noopener noreferrer" theme={theme}>
          <Card theme={theme}>
            <BookCover>
              <StaticImage
                src={"../../../assets/images/cover_chinese_phil.jpg"}
                alt={isEn ? books[0].seoTitle : books[0].seoTitleCN}
                placeholder="blurred"
                imgStyle={{objectFit: "contain"}}
                style={{height: "100%", width: "100%"}}
              />
            </BookCover>
            <h4>{isEn ? books[0].title : books[0].titleCN}</h4>
            <p>{isEn ? books[0].publisher : books[0].publisherCN}</p>
          </Card>
        </Item>
        <Item as={Link} to={"/" + (isEn ? "" : (lang + "/")) + "buddhism_and_psychology/"} theme={theme}>
          <Card theme={theme}>
            <BookCover>
              <StaticImage
                src={"../../../assets/images/buddhism-cover.jpg"}
                alt={isEn ? books[1].seoTitle : books[1].seoTitleCN}
                placeholder="blurred"
                imgStyle={{objectFit: "contain"}}
                style={{height: "100%", width: "100%"}}
              />
            </BookCover>
            <h4>{isEn ? books[1].title : books[1].titleCN}</h4>
            <p>{isEn ? books[1].publisher : books[1].publisherCN}</p>
          </Card>
        </Item>
        <Item as={Link} to={"/" + (isEn ? "" : (lang + "/")) + "freud_and_china/"} theme={theme}>
          <Card theme={theme}>
            <BookCover>
              <StaticImage
                src={"../../../assets/images/freud.jpg"}
                alt={isEn ? books[2].seoTitle : books[2].seoTitleCN}
                placeholder="blurred"
                imgStyle={{objectFit: "contain"}}
                style={{height: "100%", width: "100%"}}
              />
            </BookCover>
            <h4>{isEn ? books[2].title : books[2].titleCN}</h4>
            <p>{isEn ? books[2].publisher : books[2].publisherCN}</p>
          </Card>
        </Item>
      </Grid>
    </Wrapper>
  );
};
