import styled from 'styled-components';

export const Error = styled.span`
  color: #ff4136;
  font-size: 14px;
`;

export const Center = styled.div`
  text-align: left;

  h4 {
    font-weight: normal;
  }
`;

export const InputField = styled.div`
  position: relative;
  margin-bottom: 1rem;
  
  & input, textarea{
    border-color: black;
    border-radius: 2px;
    border-width: 1px;
    font-size: 16px;
    //max-width: 620px;
  }
`;
