import React, { useContext } from 'react';
import { ThemeContext } from 'providers/ThemeProvider';
import { Header } from 'components/theme';
import { Container } from 'components/common';
import { Details, IntroWrapper, Thumbnail, Wrapper } from './styles';
import { StaticImage } from 'gatsby-plugin-image';
import AnchorLink from 'react-anchor-link-smooth-scroll';

export const Intro = ({ lang }) => {
  const { theme } = useContext(ThemeContext);
  const isEn = lang === 'en';
  return (
    <Wrapper>
      <Header lang={lang} />
      <IntroWrapper as={Container}>
        <Details theme={theme}>
          {/*<h1>Tao Jiang</h1>*/}
          <h4>
            {lang === 'en'
              ? 'Professor of Religion and Philosophy at Rutgers University, New Brunswick.'
              : '蒋韬：罗格斯大学宗教系、哲学系教授'}
          </h4>
          <div className={'anchorLink'}>
            {/*<Link to="#books">{ isEn ? "Books" : "专著" }</Link>*/}
            {/*<Link to="#papers">{ isEn ? "Papers" : "论文"}</Link>*/}
            {/*<Link to="#about">{ isEn ? "About" : "关于"}</Link>*/}
            <AnchorLink href="#books">{isEn ? 'Books' : '专著'}</AnchorLink>
            <AnchorLink href="#papers">{isEn ? 'Papers' : '论文'}</AnchorLink>
            <AnchorLink href="#about">{isEn ? 'About' : '关于'}</AnchorLink>
          </div>
          <p>
            {lang === 'en' ? 'Contact me: ' : '联系方式：'}
            <a href="mailto:tjiang@rutgers.edu">tjiang@rutgers.edu</a>
          </p>
          {/*<Button as={AnchorLink} href="#contact">*/}
          {/*  Contact me*/}
          {/*</Button>*/}
        </Details>
        <Thumbnail>
          <StaticImage
            src={'../../../assets/images/du-jin_fu-sheng.jpg'}
            alt={
              'Tao Jiang, Professor of Chinese Philosophy and Buddhist Philosophy at Rutgers University, New Brunswick.'
            }
            placeholder="blurred"
            // layout="fixed"
            // width={500}
            // height={800}
            // style={{maxWidth: "100%", position: "relative"}}
            imgStyle={{ objectFit: 'contain' }}
            style={{ height: '100%', width: '100%' }}
          />
          {/*<img src={art} alt="Tao Jiang, Professor of Chinese Philosophy and Buddhist Philosophy at Rutgers University, New Brunswick." />*/}
        </Thumbnail>
      </IntroWrapper>
    </Wrapper>
  );
};
