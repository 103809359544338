import styled from 'styled-components';
import overlayIllustration from 'assets/illustrations/overlay.svg';

export const Wrapper = styled.div`
  padding-bottom: 4rem;
  background-image: url(${overlayIllustration});
  background-size: contain;
  background-position: right top;
  background-repeat: no-repeat;
  
  @media (max-width: 960px) {
    background-size: 50%;
    padding-bottom: 0;
  }

  @media (max-width: 400px) {
    background-image: none;
  }
`;

export const IntroWrapper = styled.div`
  padding: 4rem 0 3rem;
  display: flex;
  gap: 1.2em;
  align-items: center;
  justify-content: space-between;
  max-height: 500px;

  @media (max-width: 960px) {
    flex-direction: column;
    padding: 0;
  }
`;

export const Details = styled.div`
  flex: 1;

  @media (max-width: 960px) {
    width: 100%;
    // margin-bottom: 2rem;
  }

  h1 {
    margin-bottom: 2rem;
    font-size: 36pt;
    color: ${({ theme }) => (theme === 'light' ? '#212121' : '#fff')};

    @media (max-width: 960px) {
      mix-blend-mode: ${({ theme }) => (theme === 'light' ? 'unset' : 'difference')};
    }

    @media (max-width: 680px) {
      font-size: 30pt;
    }
  }
  
  div.anchorLink {
    //display: flex;
    //align-content: space-between;
    margin-bottom: 2em;
    
    a {
      //display: block;
      //flex: 1;
      font-size: 18px;
      margin: 1em 2em 1em 0;
    }
  }

  h4 {
    margin-bottom: 2rem;
    font-size: 25pt;
    font-weight: normal;
    line-height: 1.3;
    // color: ${({ theme }) => (theme === 'light' ? '#707070' : '#e6e6e6')};
    color: ${({ theme }) => (theme === 'light' ? '#000' : '#e6e6e6')};

    @media (max-width: 960px) {
      mix-blend-mode: ${({ theme }) => (theme === 'light' ? 'unset' : 'difference')};
    }

    @media (max-width: 680px) {
      font-size: 20pt;
    }
  }
`;

export const Thumbnail = styled.div`
  flex: 1;
  
  @media (max-width: 960px) {
    width: 100%;
    & img {
      max-height: 0;
      visibility: hidden;
    }
  }

  @media (max-width: 680px) {
    max-height: 0;
    visibility: hidden;
  }

  & img {
    width: 100%;
  }
`;
