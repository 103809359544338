import React, {useContext} from 'react';
// import AnchorLink from 'react-anchor-link-smooth-scroll';
import {ThemeContext} from 'providers/ThemeProvider';
// import { Container, Button } from 'components/common';
import {Container} from 'components/common';
// import zhao_ji from 'assets/images/zhao-ji_auspicious-cranes-left.jpg';
import {Details, SkillsWrapper, Thumbnail, Wrapper} from './styles';
import data from "../../../data/Data";
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw'
import {StaticImage} from "gatsby-plugin-image";
import Link from "gatsby-link";

export const Papers = ({lang}) => {
  const {theme} = useContext(ThemeContext);

  return (
    <Wrapper id="papers">
      <SkillsWrapper as={Container}>
        <Thumbnail>
          {/*<img src={zhao_ji} alt="Auspicious Cranes by Zhao Ji"/>*/}
          <StaticImage
              src={"../../../assets/images/zhao-ji_auspicious-cranes-left.jpg"}
              alt="Auspicious Cranes by Zhao Ji"
              placeholder="blurred"
              imgStyle={{ objectFit: "contain" }}
              style={{ height: "100%", width: "100%" }}
          />
        </Thumbnail>
        <Details theme={theme}>
          <h1>{ lang==="en" ? "Papers" : "论文" }</h1>
          {/*<span style="font-family: 'STKaiti', 'Kaiti', Helvetica, sans-serif; font-size: 20px;">*/}
          {data.papers.items.map((item, idx) =>
            <ReactMarkdown key={idx} className={idx < (lang === "en" ? 4 : 3) ? "visible" : "hidden"}
                           rehypePlugins={[rehypeRaw]}>
              {
                lang === "en"
                  ? item.title
                  : item.title + `<br>` + item.titleCN
              }
            </ReactMarkdown>
          )}
          {/*<Button as={Link} to={"/" + (lang==="en" ? "" : "cn/") + "papers/"}>*/}
          <Link to={"/" + (lang === "en" ? "" : "cn/") + "papers/"}
                style={{fontWeight: 700}}>{lang === "en" ? "More papers | Download" : "更多文章 | 下载"}</Link>
          {/*</Button>*/}
        </Details>
      </SkillsWrapper>
    </Wrapper>
  );
};
